<template>
  <div class="about">
    <h1 class="text-light pt-5">BrokeApp</h1>
    <p class="text-light ">small app that can calculate the time period it will take to get you rich</p>
    <h2 class="text-light">HowTo</h2>
    <p class="text-light ">Simply enter the monthly amount you plan to invest, the approximate increase rate and a time period to generate the overall outcome. Optional start with the outcome you would like to receive after a certain period of time, enter the expected increase rate and the app will tell you how much you will have to invest to reach that goal.</p>
    <h2 class="text-light">TechInsights</h2>
    <p2 class="text-light ">      This app will be storing user input from forms into a database, do calculations with this data and provide a view on the user screen with the results. The user can change the input data at any time, this will override the values in the database and refresh the view
    </p2>
    <h5 class="text-light pt-5">single page application by<br>mauriz weymann - s0528279</h5>
  </div>
</template>
<script>
</script>
